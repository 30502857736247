/**
 * Do not edit directly
 * Generated on Tue, 11 Jan 2022 19:03:33 GMT
 */

:root {
  --text-transform-fancy-text: uppercase;
  --font-fancy-text-large: var(--font-base-tertiary-normal-medium);
  --asset-font-mono-bold: "Univers LT Std 65 Bold";
  --asset-font-mono-normal: "Univers LT Std 55 Roman";
  --asset-font-tertiary-bold: "Univers LT Std 65 Bold";
  --asset-font-tertiary-normal: "Univers LT Std 55 Roman";
  --asset-font-secondary-bold: "Univers LT Std 65 Bold";
  --asset-font-secondary-normal: "Univers LT Std 55 Roman";
  --asset-font-primary-bold: "Univers LT Std 65 Bold";
  --asset-font-primary-normal: "Univers LT Std 55 Roman";
}
