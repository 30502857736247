$breakpoint-sm: 375px;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--asset-font-primary-normal);
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: var(--color-font-dark-primary);
}

h1 {
  font-size: var(--size-font-size-primary-xlarge);
  @media screen and (max-width: $breakpoint-sm) {
    font-size: var(--size-font-size-secondary-small);
  }
}

h2 {
  font-size: var(--size-font-size-primary-small);
}

p {
  font-family: var(--asset-font-secondary-normal);
  font-style: normal;
  font-weight: normal;
  font-size: var(--size-font-size-secondary-small);
  line-height: 20px;
  letter-spacing: -0.09px;
  &.align-left {
    text-align: left;
  }
  &.align-center {
    text-align: center;
  }
  &.align-right {
    text-align: right;
  }
  color: var(--color-font-dark-primary);

  @media screen and (max-width: $breakpoint-sm) {
    line-height: normal;
    margin: 10px 0;
  }
}

.flex-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: var(--size-spacing-4) var(--size-spacing-10);
  max-width: 650px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: $breakpoint-sm) {
    padding: var(--size-spacing-2) var(--size-spacing-4);
  }
}

.divider {
  width: 100%;
  display: flex;
  align-items: center;

  .text {
    padding: var(--size-spacing-4) var(--size-spacing-3);
    font-family: var(--asset-font-mono-normal);
    font-style: normal;
    font-weight: normal;
    font-size: var(--size-font-size-secondary-xxxsmall);
    color: var(--color-font-dark-primary);

    @media screen and (max-width: $breakpoint-sm) {
      padding: var(--size-spacing-2) var(--size-spacing-1);
    }

  }

  .line {
    border-bottom: var(--size-stroke-1) solid var(--color-border-dark-medium);
    width: 50%;
  }
}

span.validation-error {
  margin: var(--size-spacing-6) 0;
  text-align: center;
  font-family: var(--asset-font-secondary-normal);
  font-size: var(--size-font-size-secondary-small);
  color: var(--color-font-dark-warning);
  margin-top: 3px;

  @media screen and (max-width: $breakpoint-sm) {
    margin: var(--size-spacing-2) 0;
  }
}

.spinner {
  border: var(--size-primary-stroke-4) solid rgba(255,255,255,.1);
  width: var(--size-spacing-6);
  height: var(--size-spacing-6);
  border-radius: 50%;
  border-left-color: var(--color-border-light-hard);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
