@font-face { font-family: "Le Jeune Deck Light"; src: url("https://concierge-connect-assets.madcloud.io/default/fonts/LeJeuneDeck-Light.otf") format("opentype"); font-weight: 300 }
@font-face { font-family: "Le Jeune Deck Regular"; src: url("https://concierge-connect-assets.madcloud.io/default/fonts/LeJeuneDeck-Regular.otf") format("opentype"); font-weight: 400 }
@font-face { font-family: "Founders Grotesk Text Regular"; src: url("https://concierge-connect-assets.madcloud.io/default/fonts/FoundersGroteskText-Regular.otf") format("opentype"); font-weight: 400 }
@font-face { font-family: "Founders Grotesk Text Medium"; src: url("https://concierge-connect-assets.madcloud.io/default/fonts/FoundersGroteskText-Medium.otf") format("opentype"); font-weight: 500 }
@font-face { font-family: "Northwell"; src: url("https://concierge-connect-assets.madcloud.io/default/fonts/Northwell-Alt.otf") format("opentype"); font-weight: normal }
@font-face { font-family: "Northwell"; src: url("https://concierge-connect-assets.madcloud.io/default/fonts/Northwell-Alt.otf") format("opentype"); font-weight: normal }
@font-face { font-family: "Founders Grotesk Mono Regular"; src: url("https://concierge-connect-assets.madcloud.io/default/fonts/FoundersGroteskMono-Regular.otf") format("opentype"); font-weight: 400 }
@font-face { font-family: "Founders Grotesk Mono Medium"; src: url("https://concierge-connect-assets.madcloud.io/default/fonts/FoundersGroteskMono-Medium.otf") format("opentype"); font-weight: 500 }
@font-face { font-family: "Univers LT Std 55 Roman"; src: url("https://concierge-connect-assets.madcloud.io/vnext/release-21-2/fonts/Univers LT 55 Roman.woff") format("woff"); font-weight: 400 }
@font-face { font-family: "Univers LT Std 65 Bold"; src: url("https://concierge-connect-assets.madcloud.io/vnext/release-21-2/fonts/Univers LT 65 Bold.woff") format("woff"); font-weight: 700 }
@font-face { font-family: "Univers LT Std 55 Roman"; src: url("https://concierge-connect-assets.madcloud.io/vnext/release-21-2/fonts/Univers LT 55 Roman.woff") format("woff"); font-weight: 400 }
@font-face { font-family: "Univers LT Std 65 Bold"; src: url("https://concierge-connect-assets.madcloud.io/vnext/release-21-2/fonts/Univers LT 65 Bold.woff") format("woff"); font-weight: 700 }
@font-face { font-family: "Univers LT Std 55 Roman"; src: url("https://concierge-connect-assets.madcloud.io/vnext/release-21-2/fonts/Univers LT 55 Roman.woff") format("woff"); font-weight: 400 }
@font-face { font-family: "Univers LT Std 65 Bold"; src: url("https://concierge-connect-assets.madcloud.io/vnext/release-21-2/fonts/Univers LT 65 Bold.woff") format("woff"); font-weight: 700 }
@font-face { font-family: "Univers LT Std 55 Roman"; src: url("https://concierge-connect-assets.madcloud.io/vnext/release-21-2/fonts/Univers LT 55 Roman.woff") format("woff"); font-weight: 400 }
@font-face { font-family: "Univers LT Std 65 Bold"; src: url("https://concierge-connect-assets.madcloud.io/vnext/release-21-2/fonts/Univers LT 65 Bold.woff") format("woff"); font-weight: 700 }