*{
  box-sizing: border-box;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
-o-box-sizing: border-box;
-ms-box-sizing: border-box;
}

html{
  min-height: 100vh;
  max-width: 100vw;
}

body {
  margin: 0;
  padding: 0;
}

.slide {
  transition: all ease-in 400ms;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 9;
  opacity: 0;
}

.slide-enter {
  opacity: 0;
}
.slide-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.slide-exit {
  opacity: 1;
}
.slide-active {
  opacity: 0;
  transition: opacity 200ms;
}

button,
textarea,
input,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}